import { useContext } from "react";
import styled from "styled-components";
import { SalmekContext } from "../../Context/Context";
import { FiMapPin } from 'react-icons/fi';
import { SlSocialInstagram } from "react-icons/sl";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { BsFillTelephoneOutboundFill, BsWhatsapp, BsEnvelopeAt } from 'react-icons/bs';
import {BiLogoLinkedin} from "react-icons/bi";
import styles from "./Footer.module.css";
import MaineroLogo from "../../assets/img/logoMainero.png";

const H2 = styled.h2`
    margin-top: 10px;
`; 
const Img = styled.img`
    width: 500px;
`;

export function Foot() {
    const { theme } = useContext(SalmekContext);

    const containerStyles = {
      backgroundColor: theme === 'dark' ? '#343a40'  : '#ED1C24',
      color: theme === 'dark' ? '#fff' : 'white',
  };

return (
        <>
        <div id="footer" className={styles.footer} style={containerStyles}>
            <section className={styles.sectionimg}>
            <H2 style={{textAlign:"center"}}>QUIENES SOMOS:</H2>
                <p style={{textAlign:"center"}}>SALMEK INTERNATIONAL SA </p>
                <p style={{textAlign:"center"}}>RUT: 217 490 300 011</p>
                <p style={{textAlign:"center"}}>Distribuidor exclusivo Mainero en Uruguay.</p>
                <Img src={MaineroLogo} alt="Logo Mainero"/>
            </section>
            <section className={styles.sectioncontact} style={{textAlign:"center"}}>
                <H2>CONTACTO:</H2>
                <p style={{textAlign:"center"}}><FiMapPin />  Ruta 2 Km 277,8 - Mercedes </p>
                <p style={{textAlign:"center"}}><BsFillTelephoneOutboundFill />  (598) 4533 0110 </p>
                <p style={{textAlign:"center"}}><BsWhatsapp />  (598) 099 743 194</p>
                <p style={{textAlign:"center"}}><BsEnvelopeAt />  info@mainero.uy</p>
            </section>
            <section className={styles.sectionsocial}>
                <H2 style={{textAlign:"center"}}>NUESTRAS REDES SOCIALES</H2>
                <ul>
                <li><a href="https://www.instagram.com/mainero_uruguay" title="Instagram" target="_blank" rel="noreferrer">
                    <SlSocialInstagram />
                </a></li>
                <li><a href="https://www.facebook.com/mainero.uy/" title="Facebook" target="_blank" rel="noreferrer"><TiSocialFacebookCircular /></a></li>
                <li><a href="https://www.linkedin.com/in/mainerouruguay" title="Linkedin" target="_blank" rel="noreferrer"><BiLogoLinkedin /></a></li>
                </ul>
            </section>


        </div>
        <br/>
        <div className={styles.credits}>
            <p>Todos los derechos Reservados &copy; SALMEK INTERNATIONAL S.A.<br /> <a href="https://api.whatsapp.com/send?phone=5491158465481" target="_blank" rel="noreferrer">Desarrollado por Walter Liendo
            @2023</a></p>
        </div>
        <br/>
        </>
    );
}